<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Keywords
			</div>
			<V2Button class="page__info--btn" to="/admin/keywords/create">
				Add keyword
			</V2Button>
		</div>

		<div>
			<table-view
				:table="getTable"
				:showSearch="false"
			/>
		</div>
	</div>
</template>

<script>

	import V2Button           from '@/components/v2/ui/V2Button';
	import TableView   from '@/components/ui/views/tableView/TableView';
	import api         from '@/services/api';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Keywords'
		},
		components: {
			V2Button,
			TableView
		},
		data: () => ({
			keywords: [],
			newKeyword: ''
		}),
		computed: {
			getTable () {
				return {
					headings: [
						'Keyword',
						'Focuses'
					],
					rows: this.keywords.map((keyword) => {
						return {
							route: `/admin/keywords/${keyword.id}/`,
							columns: [
								keyword.title,
								keyword.focus.map((keyword) => {
									return keyword.title;
								}).join(', ')
							]
						};
					})
				};
			},
			getHeaderAction () {
				return {
					text: 'Add keyword',
					route: '/admin/keywords/create'
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/keywords',
						text: 'Keywords'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setKeywords();
		},
		methods: {
			async setKeywords () {
				const keywords = await api.admin.getAllKeywords();
				if (!keywords) {
					this.$store.commit('ui/showError');
					return;
				}
				this.keywords = keywords;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>

<style lang="scss" scoped>

.page {
  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &--title {
      width: fit-content;
    }

    &--btn {
      width: fit-content;
    }
  }
}

</style>
